import React from "react";
import { Table, TableBody, TableCell, TableRow} from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';

const SkeletonTable = () => {

    return (
        <Table style={{ width: '100%' }}>
            <TableBody>
                <TableRow>
                    <TableCell>
                        <Skeleton animation="wave" width={'100%'} height={15} style={{ marginBottom: 1 }} />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Skeleton animation="wave" width={'100%'} height={15} style={{ marginBottom: 1 }} />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Skeleton animation="wave" width={'100%'} height={15} style={{ marginBottom: 1 }} />
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
};

export default SkeletonTable;