import React, { useState, useRef, useEffect, useCallback } from "react";
import {
    Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, Button, TextField, 
    LinearProgress, FormControlLabel, Checkbox
} from "@material-ui/core";
import { connect } from "react-redux";
import { API } from "aws-amplify";
import Alert from '@material-ui/lab/Alert';
import { Row, Col } from "react-bootstrap";

const mapStateToProps = (state, props) => ({
    productSelected: state.productSelected,
    instanciaSelect: state.instanciaSelect,
    institucionId: props.institucionId,
    managerEdit: props.managerEdit,
    open: props.open,
    setOpen: props.setOpen
});

const mapDispatchToProps = dispatch => ({
    showError(error) {
        dispatch({
            type: "SHOW_ERROR",
            error
        })
    },
});

const DialogAddRocketManager = ({open, setOpen, showError, productSelected, managerEdit, instanciaSelect, institucionId}) => {

    const [nombre, setNombre] = useState('');
    const [apellido, setApellido] = useState('');
    const [email, setEmail] = useState('');
    const [identificacion, setIdentificacion] = useState('');
    const [estado, setEstado] = useState(true);
    const [metodoPago, setMetodoPago] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);

    const productoId = useRef(productSelected.productoId);
    const isFirstRender = useRef(true);
    const manData = useRef(null);

    const handleClose = useCallback(() => {
        setOpen(false);
    },[setOpen]);

    const getManagerData = useCallback(async() => {
        setIsLoading(true);
        try {
            const qsp = {
                productoId: productoId.current.trim().toLowerCase(),
                prefijo: instanciaSelect.trim().toLowerCase(),
                institucionId: institucionId,
                usuarioId: managerEdit,
                get: ['nombre', 'apellido', 'email', 'identificacion', 'estado', 'metodoPago'],
            };
            const managerData = await API.get("imperium", "/getManager",{
                queryStringParameters: qsp,
            });
            setIsLoading(false);
            if (managerData.fail){
                console.log(managerData);
                setErrorMessage(managerData.description)
            }
            setNombre(managerData.nombre);
            setApellido(managerData.apellido);
            setEmail(managerData.email);
            setIdentificacion(managerData.identificacion);
            setEstado(Boolean(managerData.estado));
            setMetodoPago(Boolean(managerData.metodoPago));
            setIsLoading(false);
            manData.current = managerData;
        } catch (error) {
            setIsLoading(false);
            if(error.show){
                handleClose();
                showError(error);
            } else {
                console.log(error);
                const erro = {
                    show: true,
                    code: "AppException",
                    description: "Error en la aplicación",
                }
                handleClose();
                showError(erro);
            }
        }
    }, [instanciaSelect, institucionId, managerEdit, handleClose, showError]);

    useEffect(() => {
        if(isFirstRender.current){
            isFirstRender.current = false;
            if(managerEdit){
                getManagerData();
            } else {
                setIsLoading(false);
            }
        }
    }, [managerEdit, getManagerData]);

    const handleUpdateManager = async() => {
        setErrorMessage(null);
        setIsLoading(true);
        const cambios = [];
        if(nombre !== manData.current.nombre){
            const name = {
                propiedad: 'nombre',
                value: nombre.trim().toLowerCase(),
            };
            cambios.push(name);
        }
        if(apellido !== manData.current.apellido){
            const lastname = {
                propiedad: 'apellido',
                value: apellido.trim().toLowerCase(),
            };
            cambios.push(lastname);
        }
        if(email !== manData.current.email){
            const mail = {
                propiedad: 'email',
                value: email.trim().toLowerCase(),
            };
            cambios.push(mail);
        }
        if(identificacion !== manData.current.identificacion){
            const id = {
                propiedad: 'identificacion',
                value: identificacion.trim(),
            };
            cambios.push(id);
        }
        if(estado !== Boolean(manData.current.estado)){
            const enable = {
                propiedad: 'estado',
                value: estado
            };
            cambios.push(enable);
        }
        if(metodoPago !== Boolean(manData.current.metodoPago)){
            const mp = {
                propiedad: 'metodoPago',
                value: metodoPago
            };
            cambios.push(mp);
        }
        if(cambios.length > 0){
            const data = {
                productoId: productoId.current.trim().toLowerCase(),
                prefijo: instanciaSelect.trim().toLowerCase(),
                institucionId: institucionId,
                usuarioId: managerEdit,
                propiedades: cambios,
            };
            try{
                const result = await API.post("imperium", "/updateManager",{
                    body: data,
                });
                if(result.fail){
                    console.log(result);
                    setErrorMessage(result.description)
                    setIsLoading(false);
                } else {
                    handleClose();
                }
            } catch (error){
                setIsLoading(false);
                if(error.show){
                    handleClose();
                    showError(error);
                } else {
                    console.log(error);
                    const erro = {
                        show: true,
                        code: "AppException",
                        description: "Error en la aplicación",
                    }
                    handleClose();
                    showError(erro);
                }
            }
        } else {
            setErrorMessage('No hay cambios que guardar');
        }
    };

    const handleSaveManager = async() => {
        setIsLoading(true);
        setErrorMessage(null);
        try {
            const data = {
                productoId: productoId.current.trim().toLowerCase(),
                prefijo: instanciaSelect.trim().toLowerCase(),
                institucionId: institucionId,
                nombre: nombre.trim().toLowerCase(),
                apellido: apellido.trim().toLowerCase(),
                email: email.trim().toLowerCase(),
                identificacion: identificacion.trim(),
                estado: estado,
                metodoPago: metodoPago,
            };
            const response = await API.post("imperium", "/crearManager",{
                body: data
            });
            setIsLoading(false);
            if (response.fail){
                console.log(response);
                setErrorMessage(response.description)
            } else {
                handleClose();
            }
        } catch (error) {
            setIsLoading(false);
            if(error.show){
                handleClose();
                showError(error);
            } else {
                console.log(error);
                const erro = {
                    show: true,
                    code: "AppException",
                    description: "Error en la aplicación",
                }
                handleClose();
                showError(erro);
            }
        }
    };

    const enableSaveManager = () => {
        return (nombre ? nombre.length > 3 : false) &&
        (apellido ? apellido.length > 3 : false) &&
        (email ? email.length > 3 : false) &&
        (email ? email.includes('@') : false) &&
        (identificacion ? identificacion.length > 3 : false)
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="add-manager-dialog"
            disableBackdropClick
            disableEscapeKeyDown
        >
            <DialogTitle id="add-manager-dialog">{managerEdit ? 'Editar manager' : 'Nuevo Manager'}</DialogTitle>
            <DialogContent>
                <DialogContentText variant='h6'>
                    {managerEdit ? 
                        'Editando manager ' + email
                    :
                        'Vamos a crear un nuevo manager para el producto '  + productSelected.productoId +"!"
                    }
                    
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="nombre"
                    label="Nombre"
                    type="text"
                    value={nombre}
                    onChange={e=>setNombre(e.target.value)}
                    fullWidth
                    InputProps={{
                        readOnly:isLoading
                    }}
                />
                <TextField
                    margin="dense"
                    id="apellido"
                    label="Apellido"
                    type="text"
                    value={apellido}
                    onChange={e=>setApellido(e.target.value)}
                    fullWidth
                    InputProps={{
                        readOnly:isLoading
                    }}
                />
                <TextField
                    margin="dense"
                    id="email"
                    label="Email"
                    type="text"
                    value={email}
                    onChange={e=>setEmail(e.target.value)}
                    fullWidth
                    InputProps={{
                        readOnly:isLoading
                    }}
                />
                <TextField
                    margin="dense"
                    id="identificacion"
                    label="Identificación"
                    type="text"
                    value={identificacion}
                    onChange={e=>setIdentificacion(e.target.value)}
                    fullWidth
                    InputProps={{
                        readOnly:isLoading
                    }}
                />
                <Row>
                    <Col>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={estado}
                                    onChange={()=>setEstado(!estado)}
                                    name="estado"
                                    color="primary"
                                />
                            }
                            label="Activo"
                        />
                    </Col>
                    <Col>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={metodoPago}
                                    onChange={()=>setMetodoPago(!metodoPago)}
                                    name="metodoPago"
                                    color="primary"
                                />
                            }
                            label="Metodo pago"
                        />
                    </Col>
                </Row>
            </DialogContent>
            {isLoading ?
                <LinearProgress />
                :
                <></>
            }
            <DialogActions>
                {errorMessage ? 
                    <Alert severity="error">{errorMessage}</Alert>
                :
                    <></>    
                }
                <Button onClick={handleClose} 
                    variant="outlined" 
                    color="secondary"
                    disabled={isLoading}
                >
                    Cancelar
                </Button>
                <Button 
                    onClick={managerEdit ? ()=>handleUpdateManager() : ()=>handleSaveManager()} 
                    disabled={isLoading ? isLoading : !enableSaveManager()}
                    variant="contained" 
                    color="secondary"
                    disableElevation
                >
                    Guardar
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(DialogAddRocketManager);