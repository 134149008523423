import { createStore } from "redux";

const initialState = {
    menuState: false,
    error: {
        show: false,
        code: null,
        description: null,
    },
    products: [],
    productSelected: {},
    actualPage: 1,
    instanciaSelect: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case "LOAD_HEADER":
            return {
                ...state,
                loadHeader: action.load_header
            }
        case "OPEN_MENU":
            return {
                ...state,
                menuState: action.open_menu
            }
        case "SET_PRODUCTS":
            return {
                ...state,
                products: action.products
            }
        case "SET_PRODUCT_SELECTED":
            return {
                ...state,
                productSelected: action.product_selected
            }
        case "SET_ACTUAL_PAGE":
            return {
                ...state,
                actualPage: action.actual_page
            }
        case "SET_INSTANCIA_SELECTED":
            return {
                ...state,
                instanciaSelect: action.prefijo
            }
        case "SHOW_ERROR":
            return {
                ...state,
                error: action.error
            }
        default:
            break;
    }
    return state;
}; 

export default createStore(reducer);