import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Form, Modal } from "react-bootstrap";
import { Button, LinearProgress, TextField } from '@material-ui/core';
import Logo from "../img/logo.png";
import { Auth } from "aws-amplify";
import ErrorIcon from '@material-ui/icons/Error';
import { useHistory } from "react-router-dom";

const Login = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState('');
    const [clave, setClave] = useState('');
    const [showErrorModal, setShowErrorModal] = useState(false);
    const history = useHistory();

    const modalMsg = useRef('');

    const handleError = (exception) => {
        switch (exception.code) {
            case 'UserNotFoundException':
                modalMsg.current = "Este usuario no existe";
                break;
            case 'NEW_PASSWORD_REQUIRED':
                modalMsg.current = "Este usuario debe cambiar su contraseña";
                break;
            case 'NotAuthorizedException':
                modalMsg.current = "Usuario o clave incorrectos";
                break;
            default:
                modalMsg.current = "Se presento un error...";
                break;
        }
        setShowErrorModal(true);
    };

    const handleLogin = async() => {
        setIsLoading(true);
        try{
            const userObject = await Auth.signIn(user, clave);
            if(userObject.challengeName === 'NEW_PASSWORD_REQUIRED'){
                const err = 
                {
                    code: 'NEW_PASSWORD_REQUIRED'
                }
                throw (err);
            }
            //console.log(userObject);
            history.push("/");
        } catch (error){
            console.log(error);
            handleError(error);
            setIsLoading(false);
        }
    };

    useEffect(()=>{
        const isUserSign = async() =>{
            setIsLoading(true);
            try{
                await Auth.currentAuthenticatedUser();
                history.push("/");
            } catch (error){
                console.log(error);
                setIsLoading(false);
            }
        };
        isUserSign();
    },[history]);

    const handleDisabled = () => {
        return user.includes("@") &&
        user.length > 3 && 
        clave.length > 3
    };

    const handleCloseErrorModal = () => {
        setShowErrorModal(false);
    }

    return (
        <div >
            <Modal
                show={showErrorModal}
                onHide={() => handleCloseErrorModal()}
            >
                <Modal.Header closeButton>
                    <Modal.Title><ErrorIcon color="error" /></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalMsg.current}
                </Modal.Body>
            </Modal>
            <Row className="mt-5">
                <Col className="text-center">
                    <img className="login-logo" alt="imperium logo" src={Logo}></img>
                </Col>
            </Row>
            {isLoading ?
                <Row className="justify-content-center">
                    <Col sm={6} md={5}>
                        <LinearProgress />
                    </Col>
                </Row>
                :
                <></>
            }
            <Row className="justify-content-center">
                <Col className="mt-1" sm={6} md={5}>
                    <TextField 
                        id="username" 
                        label="Usuario"
                        type="email"
                        value={user}
                        onChange={e=>setUser(e.target.value)}
                        InputProps={{
                            readOnly:isLoading
                        }}
                        fullWidth
                    />
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col className="mt-3" sm={6} md={5}>
                    <TextField 
                        id="clave" 
                        label="Clave"
                        type="password"
                        value={clave}
                        onChange={e=>setClave(e.target.value)}
                        InputProps={{
                            readOnly:isLoading
                        }}
                        fullWidth
                    />
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col className="mt-4" sm={6} md={5}>
                    <Button 
                        variant="contained" 
                        color="secondary" 
                        fullWidth
                        disableElevation
                        onClick={()=>handleLogin()}
                        disabled={isLoading ? isLoading : !handleDisabled()} >
                        Ingresar
                    </Button>
                </Col>
            </Row>
            <Row className="login-footer">
                <Col className="text-center align-self-center">
                    <Form.Text className="text-muted footer-login-text">
                        © Grupo Valor International Solutions - Todos los derechos reservados.
                    </Form.Text>
                </Col>
            </Row>
        </div>
    )
};

export default Login;