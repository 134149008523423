import React, { useEffect, useRef, useState } from "react";
import { API } from "aws-amplify";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { MenuItem, FormControl, InputLabel, 
    Select, LinearProgress } from "@material-ui/core";
import InstitucionesPorInstancia from "./institucionesPorInstancia";

const mapStateToProps = state => ({
    productSelected: state.productSelected
});

const mapDispatchToProps = dispatch => ({
    showError(error) {
        dispatch({
            type: "SHOW_ERROR",
            error
        })
    },
    setInstanciaSelect(prefijo){
        dispatch({
            type: "SET_INSTANCIA_SELECTED",
            prefijo
        })
    },
});

const InstitucionesProducto = ({productSelected, showError, setInstanciaSelect}) => {

    const [isLoading, setIsLoading] = useState(true);
    const [instanceIsSelected, setInstanceIsSelected] = useState(false);
    const [instanciaSelected, setInstanciaSelected] = useState('');

    const isFirstRender = useRef(true);
    const instancias = useRef([]);

    useEffect(()=>{
        const loadInstancias = async() => {
            try {
                const instanciasData = await API.get("imperium","/getInstancias",{
                    queryStringParameters:{
                        get: ['nombre', 'prefijo'],
                        productoId: productSelected.productoId
                    }
                });
                if(instanciasData.fail){
                    console.log(instanciasData);
                    const err = {
                        show: true,
                        code: "GetInstanciasException",
                        description: instanciasData.description,
                    }
                    throw(err);
                }
                instancias.current = instanciasData;
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                if(error.show){
                    showError(error);
                } else {
                    console.log(error);
                    const erro = {
                        show: true,
                        code: "AppException",
                        description: "Error en la aplicación",
                    }
                    showError(erro);
                }
            }
        };
        if(isFirstRender.current){
            isFirstRender.current = false;
            loadInstancias();
        }
    },[productSelected, showError]);

    const handleInstanciaSelected = (value) => {
        setInstanciaSelected(value);
        setInstanciaSelect(value);
        setInstanceIsSelected(true);
    };
    return(
        <div>
            {isLoading ?
                <LinearProgress className="mt-3" />
            :
                
                <div>
                    <Row className="mt-3">
                        <Col>
                            <h4>De cual instancia desea consultar las instituciones: </h4>    
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormControl 
                                fullWidth
                            >
                                <InputLabel id="instancias-label">Instancia:</InputLabel>
                                <Select
                                    labelId="instancias-label"
                                    id="instancias-select"
                                    value={instanciaSelected}
                                    onChange={e => handleInstanciaSelected(e.target.value)}
                                    >
                                        {instancias.current.map(x=>{
                                            return (
                                                <MenuItem 
                                                    key={x.prefijo} 
                                                    value={x.prefijo}
                                                >{x.prefijo}: {x.nombre.charAt(0).toUpperCase() + x.nombre.slice(1)}</MenuItem>
                                            )
                                        })}
                                </Select>
                            </FormControl>
                        </Col>
                    </Row>
                    <hr></hr>
                    <Row>
                        <Col>
                            {instanceIsSelected && <InstitucionesPorInstancia />}
                        </Col>
                    </Row>
                </div>
            }
        </div>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(InstitucionesProducto);