import React, { useState, useRef, useEffect } from "react";
import { API } from "aws-amplify";
import { Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { useCallback } from "react";
import SkeletonTable from "./skeletonTable";
import { Paper } from "@material-ui/core";
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from "react-bootstrap-table2-paginator";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ManagersPorInstancia from "./managersPorInstancia";
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DeleteIcon from '@material-ui/icons/Delete';
import DialogAddInstitucion from "./dialogAddInstitucion";

const mapStateToProps = state =>({
    productSelected: state.productSelected,
    instanciaSelect: state.instanciaSelect
}); 

const mapDispatchToProps = dispatch => ({
    showError(error) {
        dispatch({
            type: "SHOW_ERROR",
            error
        })
    },
});

const columns = [
    {
        dataField:"nombre", 
        text:"Nombre",
        title: true,
        style: { overflow: 'hidden', textOverflow: 'ellipsis', textTransform: "capitalize" },
        headerStyle: {
            width: '15%',
            overflow: 'hidden', 
            textOverflow: 'ellipsis',
        }
    },
    {
        dataField: "alias", 
        text: "Alias",
        title: true,
        style: { overflow: 'hidden', textOverflow: 'ellipsis', textTransform: "capitalize" },
        headerStyle: {
            overflow: 'hidden', 
            textOverflow: 'ellipsis',
        }
    },
    {
        dataField: "direccion", 
        text: "Direccion",
        title: true,
        style: { overflow: 'hidden', textOverflow: 'ellipsis' },
        headerStyle: {
            overflow: 'hidden', 
            textOverflow: 'ellipsis',
        }
    },
    {
        dataField: "licencia", 
        text: "Licencia",
        title: true,
        style: { overflow: 'hidden', textOverflow: 'ellipsis' },
        headerStyle: {
            overflow: 'hidden', 
            textOverflow: 'ellipsis',
        }
    },
    {
        dataField: "precio", 
        text: "Precio",
        title: true,
        style: { overflow: 'hidden', textOverflow: 'ellipsis' },
        headerStyle: {
            overflow: 'hidden', 
            textOverflow: 'ellipsis',
        }
    },
    {
        dataField: "licencias", 
        text: "Licencias",
        title: true,
        style: { overflow: 'hidden', textOverflow: 'ellipsis' },
        headerStyle: {
            overflow: 'hidden', 
            textOverflow: 'ellipsis',
        }
    },
];

// const useStyles = makeStyles((theme)=>({
//     btnBox: {
//         border: '1px solid '+theme.palette.secondary.contrastText,
//         // borderStyle: 'solid',
//         // borderColor: theme.palette.primary.main,
//         borderRadius: 8
//     },
//     btnsMargin: {
//         marginBottom: theme.spacing(0.5),
//         marginTop: theme.spacing(0.5)
//     }
// }));

const InstitucionesPorInstancia = ({productSelected, instanciaSelect, showError}) => {

    const [isLoading, setIsLoading] = useState(true);
    const [enableDelInstitucion,setEnableDelInstitucion] = useState(false);
    const [showAddInstitucionDialog, setShowAddInstitucionDialog] = useState(false);
    
    const localInstance = useRef(null);
    const institucionesData = useRef([]);
    const rowSelected = useRef(null);
    const keyFieldTable = useRef('institucionId')

    const loadInstituciones = useCallback(async()=>{
        setIsLoading(true);
        institucionesData.current = [];
        rowSelected.current = null;
        setEnableDelInstitucion(false);
        try {
            const qsp = {
                productoId: productSelected.productoId,
                prefijo: localInstance.current,
            }
            const instituciones = await API.get("imperium","/getInstituciones",{
                queryStringParameters: qsp,
            });
            if(instituciones.fail){
                console.log(instituciones);
                const err = {
                    show: true,
                    code: "GetInstitucionesException",
                    description: instituciones.description,
                }
                throw(err);
            }
            switch (productSelected.productoId) {
                case 'rocket':
                    keyFieldTable.current = 'institucionId'
                    break;
                case 'cristal':
                    keyFieldTable.current = 'clienteId'
                    break;
                default:
                    break;
            }
            institucionesData.current = instituciones;
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            if(error.show){
                showError(error);
            } else {
                console.log(error);
                const erro = {
                    show: true,
                    code: "AppException",
                    description: "Error en la aplicación",
                }
                showError(erro);
            }
        }
    },[productSelected, showError]);

    // useEffect(()=>{

    //     if(isFirstRender.current){
    //         isFirstRender.current = false;
    //         loadInstituciones();
    //     }
    // },[]);

    useEffect(()=>{
        localInstance.current = instanciaSelect;
        loadInstituciones();
    },[instanciaSelect, loadInstituciones])

    const onRowExpand = (row) => {
        var instId = null;
        switch (productSelected.productoId) {
            case 'rocket':
                instId = row.institucionId;
                break;
            case 'cristal':
                instId = row.clienteId;
                break;
            default:
                break;
        }
        return(
            <ManagersPorInstancia 
                institucionId={instId}/>
        )
    };

    const handleRowExpanded = (row, isExpand, rowIndex, e) => {
        // if(isExpand){
        //     rowSelected.current = row;
        //     // setEnableDelInstitucion(true);
        // } else {
        //     rowSelected.current = null;
        //     // setEnableDelInstitucion(false);
        // }
    };

    const expandRow = {
        onlyOneExpanding: true,
        showExpandColumn: true,
        expandByColumnOnly: true,
        renderer: row => onRowExpand(row),
        onExpand: (row, isExpand, rowIndex, e) => handleRowExpanded(row, isExpand, rowIndex, e),
        expandColumnRenderer: ({ expanded }) => {
            if (expanded) {
                return (
                    <ArrowDropDownIcon />
                );
            }
            return (
                <ArrowRightIcon />
            );
        },
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            if (isAnyExpands) {
                return (
                    <ArrowDropDownIcon />
                );
            }
            return (
                <ArrowRightIcon />
            );
        }
    };

    // const handleSaveValue = async(oldValue, newValue, row, column) => {
    //     if(oldValue !== newValue){

    //     } else {
    //         console.log("Nada que salvar");
    //     }
    // };

    const handleAddInstitucion = async() => {
        setShowAddInstitucionDialog(true);
    };

    const handleEditInstitucion = async() => {
        setShowAddInstitucionDialog(true);
    };

    const handleDelInstitucion = async() => {
        //Mostrar advertencia con ingreso de texto de palabra eliminar 
        //para confirmar eliminación, indicar que se eliminar toda 
        //la data relacionada a la institucion
        //Data a eliminar
        //Tabla de institucion
        //Rocket: Todos los usuarios de la institucion en la tabla usuarios
        //Rocket: todos los cursos de la institución tabla cursos
        //Rocket: toda configuracion de la institucion tabla configuracion
        //Rocket: todos los anuncios de la institucion tabla anuncios
        //Rocket: todos los egresos de la institucion tabla egresos
        //Rocket: todos los ingresos de la institucion tabla ingresos
        //Rocket: todos los integraciones de la institucion tabla integraciones
        //Rocket: todas los obligaciones de la institucion tabla obligaciones
        //Cristal: todos los clientes de la institucion tabla clientes
        //Cristal: todos los clientes de la institucion tabla clientes
        //Cristal: todos los egresos de la institucion tabla egresos
        //Cristal: todo el inventario de la institucion tabla inventario
        //Cristal: todos los locales de la institucion tabla locales
        //Cristal: Todos los usuarios de la institucion en la tabla usuarios
        //Cristal: Todos las ventas de la institucion en la tabla ventas
        //Cristal: Todos las ventas diferidas de la institucion en la tabla ventas diferidas
    };

    const handleOnSelect = (row, isSelect) => {
        if(isSelect){
            rowSelected.current = row;
            setEnableDelInstitucion(true);
        } else {
            rowSelected.current = null;
            setEnableDelInstitucion(false);
        }
    };

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        //clickToEdit: true,
        onSelect: handleOnSelect,
        //onSelectAll: handleOnSelectAll
    };

    const handleId = () => {
        if(rowSelected.current){
            switch (productSelected.productoId) {
                case 'rocket':
                    return rowSelected.current.institucionId
                case 'cristal':
                    return rowSelected.current.clienteId
                default:
                    break;
            }
        } else {
            return null
        }
    };

    return (
        <div>
            {showAddInstitucionDialog && 
                <DialogAddInstitucion
                    open={showAddInstitucionDialog} 
                    setOpen={setShowAddInstitucionDialog}
                    institucionId={handleId()}
            />}
            {isLoading ? 
                <SkeletonTable />
            :   
                <div>
                    <Row className="mt-3">
                        <Col>
                            <h4>Instituciones: </h4>
                        </Col>
                        <Col className="text-right container-botonera mr-3" md={'auto'} sm={'auto'}>
                            <Button 
                                    className="custom-primary-btn"
                                    onClick={rowSelected.current ? ()=>handleEditInstitucion() : ()=>handleAddInstitucion()}
                                >{rowSelected.current ? <EditIcon /> : <AddIcon />}</Button>
                                <Button 
                                    className="custom-primary-btn ml-1 mr-3"
                                    onClick={()=>loadInstituciones()}
                                ><AutorenewIcon /></Button>
                                <Button 
                                    className="custom-danger-btn"
                                    onClick={()=>handleDelInstitucion()}
                                    disabled={!enableDelInstitucion} 
                                ><DeleteIcon /></Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Paper >
                                <BootstrapTable 
                                    keyField={keyFieldTable.current}
                                    data={institucionesData.current}
                                    columns={columns}
                                    noDataIndication="No se encontrarón instituciones"
                                    expandRow={expandRow}
                                    selectRow={selectRow}
                                    pagination={paginationFactory()}
                                    // cellEdit={cellEditFactory({
                                    //     mode: 'dbclick',
                                    //     blurToSave: true,
                                    //     afterSaveCell: handleSaveValue
                                    // })}
                                />
                            </Paper>
                        </Col>
                    </Row>
                </div>
            }
        </div>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(InstitucionesPorInstancia);