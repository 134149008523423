import API from "@aws-amplify/api";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { DataGrid, GridColDef } from '@material-ui/data-grid';
import { Button, makeStyles, Table, TableBody, 
    TableCell, TableRow, Menu, MenuItem } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DialogAddInstances from "./dialogAddInstances";
import DeleteIcon from '@material-ui/icons/Delete';
import SkeletonTable from "./skeletonTable";

const mapStateToProps = state => ({
    productSelected: state.productSelected
});
const mapDispatchToProps = dispatch => ({
    showError(error) {
        dispatch({
            type: "SHOW_ERROR",
            error
        })
    },
});

const useStyles = makeStyles((theme)=>({
    btnBox: {
        border: '1px solid '+theme.palette.secondary.contrastText,
        // borderStyle: 'solid',
        // borderColor: theme.palette.primary.main,
        borderRadius: 8
    },
    btnsMargin: {
        marginBottom: theme.spacing(0.5),
        marginTop: theme.spacing(0.5)
    }
}));

const columns: GridColDef[] = [
    { field: 'nombre', headerName: 'Nombre', width: 350, editable: true},
    { field: 'prefijo', headerName: 'Prefijo', width: 150},
    { field: 'userPoolId', headerName: 'userPoolId', width: 200, editable: true},
    { field: 'urlAccess', headerName: 'URL', width: 250, editable: true},
];

const ConfiguracionProducto = ({productSelected, showError}) => {

    const [isLoading, setIsLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [editRowsModel, setEditRowsModel] = useState({});
    const [isInstanceSelected, setIsInstanceSelected] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    
    const classes = useStyles();

    const instancias = useRef([]);
    const isFirstRender = useRef(true);
    const instanceSelectedIndex = useRef(null);

    const loadInstancias = useCallback(async() => {
        setIsLoading(true);
        try {
            const instanciasData = await API.get("imperium","/getInstancias",{
                queryStringParameters:{
                    get: ['nombre', 'prefijo', 'userPoolId', 'urlAccess'],
                    productoId: productSelected.productoId
                }
            });
            if(instanciasData.fail){
                console.log(instanciasData);
                const err = {
                    show: true,
                    code: "GetInstanciasException",
                    description: instanciasData.description,
                }
                throw(err);
            }
            // console.log(instanciasData);
            for (let index = 0; index < instanciasData.length; index++) {
                instanciasData[index].id = index;
                instanciasData[index].nombre = instanciasData[index].nombre.charAt(0).toUpperCase() + instanciasData[index].nombre.slice(1);
            }
            instancias.current = instanciasData;
            //setInstancias(instanciasData);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            if(error.show){
                showError(error);
            } else {
                console.log(error);
                const erro = {
                    show: true,
                    code: "AppException",
                    description: "Error en la aplicación",
                }
                showError(erro);
            }
        }
    },[productSelected, showError]);

    useEffect(() => {
        
        if(isFirstRender.current){
            isFirstRender.current = false
            loadInstancias();
        }
    },[loadInstancias]);

    const handleAddInstances = () => {
        setOpen(true);
    };

    // const handleEditRowModelChange = (params) => {
        
    //     setEditRowsModel(params.model);
    // }; 

    const handleEditInstance = async(dataField, oldData) => {
        //console.log(dataField);
        try {
            if(dataField.props.value.length === 0){
                const err = {
                    show: true,
                    code: "InvalidValueException",
                    description: "El campo no puede estar vacio"
                };
                throw (err);
            }
            const data = {
                prefijo: instancias.current[dataField.id].prefijo,
                params: [
                    {
                        propiedad: dataField.field,
                        value: dataField.props.value
                    }
                ]
            };
            // console.log(data);
            const result = await API.put("imperium", "/instancias/"+productSelected.productoId,{
                body: data
            });
            if(result.fail){
                console.log(result);
                const err = {
                    show: true,
                    code: "UpdateInstanciaException",
                    description: "Error almacenando cambios"
                };
                throw (err);
            }
            console.log(result);
            instancias.current[dataField.id].[dataField.field] = dataField.props.value;
        } catch (error) {
            console.log(error);
            if(error.show){
                showError(error);
            } else {
                console.log(error);
                const erro = {
                    show: true,
                    code: "AppException",
                    description: "Error en la aplicación",
                }
                showError(erro);
            }
            const newState = {};
            newState[dataField.id] = {
            ...editRowsModel[dataField.id],
            [dataField.field]: { value: oldData },
            };
            setEditRowsModel((state) => ({ ...state, ...newState }));
        }
    };

    const handleEditCellChangeCommitted = (dataField) => {
        const oldData = instancias.current[dataField.id].[dataField.field];
        if(oldData !== dataField.props.value){
            handleEditInstance(dataField, oldData);
        }
    };

    const handleSelectionModel = (selection) => {
        setIsInstanceSelected(true);
        instanceSelectedIndex.current = selection.selectionModel[0];
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDeleteInstances = async() => {
        handleClose();
        setIsLoading(true);
        try {
            const newState = instancias.current.map(
                i => ({...i})
            )
            newState.splice(instanceSelectedIndex.current, 1);
            const data = {
                prefijo: instancias.current[instanceSelectedIndex.current].prefijo
            };
            const result = await API.del("imperium","/instancias/"+productSelected.productoId,{
                body: data
            });
            if(result.fail){
                console.log(result);
                const err = {
                    show: true,
                    code: "DeleteInstanciaException",
                    description: "Error borrando la instancia"
                };
                throw (err);
            }
            console.log(result);
            instancias.current = newState;
            instanceSelectedIndex.current = null;
            setIsInstanceSelected(false);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            if(error.show){
                showError(error);
            } else {
                console.log(error);
                const erro = {
                    show: true,
                    code: "AppException",
                    description: "Error en la aplicación",
                }
                showError(erro);
            }
        }
    }

    return(
        <div>
            {open && <DialogAddInstances open={open} setOpen={setOpen}/>}
            {isLoading ? 
                <Row className="mt-3">
                    <Col>
                        <SkeletonTable />
                    </Col>
                </Row>
            :
                <>
                    <Row className="justify-content-end">
                        <Col className={"text-right mr-3 " + classes.btnBox}  sm={'auto'}>
                            <Button 
                                className={classes.btnsMargin} 
                                onClick={()=> handleAddInstances()}
                            ><AddIcon color="secondary"/></Button>
                            <Button 
                                className={classes.btnsMargin} 
                                onClick={()=> loadInstancias()}
                            ><AutorenewIcon color="secondary"/></Button>
                            <Button
                                aria-controls="delete-btn"
                                color="inherit"
                                className={classes.btnsMargin} 
                                aria-haspopup="true"
                                onClick={handleClick}
                                disabled={!isInstanceSelected}
                            ><DeleteIcon color="error"/></Button>
                            <Menu
                                id="delete-btn"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem
                                    color="primary"
                                    onClick={handleDeleteInstances}
                                >¿Confirma eliminar esta instancia?</MenuItem>
                            </Menu>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col style={{ height: 300, width: '100%' }}>
                            {instancias.current.length > 0 ? 
                                <DataGrid 
                                    rows={instancias.current} 
                                    columns={columns} 
                                    onSelectionModelChange={handleSelectionModel}
                                    editRowsModel={editRowsModel}
                                    onEditCellChangeCommitted={handleEditCellChangeCommitted} 
                                />
                            :
                            <Table style={{ width: '100%' }}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <h4>No hay instancias que mostrar</h4>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            }
                        </Col>
                    </Row>
                </>
            }
        </div>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfiguracionProducto);