import { Tabs, Tab } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import LayersIcon from '@material-ui/icons/Layers';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import BarChartIcon from '@material-ui/icons/BarChart';
import InstitucionesProducto from "../components/institucionesProducto";
import AnaliticsProducto from "../components/analiticsProducto";
import ConfiguracionProducto from "../components/configuracionProducto";

const mapStateToProps = state => ({
    productSelected: state.productSelected
});

const mapDispatchToProps = dispatch => ({
    setProductSelected(product_selected) {
        dispatch({
            type: "SET_PRODUCT_SELECTED",
            product_selected
        })
    },
});

const ProductData = ({productSelected, setProductSelected}) => {

    const [tabSelected, setTabSelected] = useState(0);

    const handleChange = (event, newValue) => {
        setTabSelected(newValue);
    };

    const renderTab = () => {
        switch (tabSelected) {
            case 0:
                return <InstitucionesProducto />
            case 1:
                return <AnaliticsProducto />
            case 2: 
                return <ConfiguracionProducto />
            default:
                return <></>
        }
    };

    useEffect(()=>{
        return function cleanUp(){
            setProductSelected({});
        }
    },[setProductSelected]);

    return (
        <div>
            <Row>
                <Col>
                    <Tabs 
                        value={tabSelected}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="on"
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab 
                            className="btn-capitalize" 
                            label="Instituciones" 
                            icon={<AccountBalanceIcon />} />
                        <Tab 
                            className="btn-capitalize"
                            label="Analitics" 
                            icon={<BarChartIcon />} />
                        <Tab 
                            className="btn-capitalize" 
                            label="Instancias" 
                            icon={<LayersIcon />} />
                    </Tabs>
                </Col>
            </Row>
            <Row>
                <Col>
                    {renderTab()}
                </Col>
            </Row>
        </div>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductData);