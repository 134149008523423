import React, { useState, useRef, useEffect } from "react";
import { Auth, API } from "aws-amplify";
import { connect } from "react-redux";
import {
    AppBar, Button, IconButton,
    Toolbar, Typography, Menu, MenuItem
} from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from "@material-ui/core/styles";
import Logo from "../img/logoBlank.png";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useHistory } from "react-router";

const mapStateToProps = state => ({
    menuState: state.menuState,
});

const mapDispatchToProps = dispatch => ({
    setOpenMenu(open_menu) {
        dispatch({
            type: "OPEN_MENU",
            open_menu
        })
    }
});

const Header = ({ menuState, setOpenMenu }) => {

    const [isLoadingUser, setIsLoadingUser] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const history = useHistory();

    const userName = useRef(null);

    const useStyles = makeStyles((theme) => ({
        menuButton: {
            marginRight: theme.spacing(2),
        },
        skeletonAvatar: {
            marginRight: theme.spacing(1),
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
        }
    }));

    useEffect(() => {
        const loadUserData = async () => {
            try {
                const cu = await Auth.currentAuthenticatedUser();
                const response = await API.get('imperium', '/usuarios/' + cu.username, {
                    queryStringParameters: {
                        get: ['nombre', 'apellido']
                    }
                });
                userName.current = response.nombre.charAt(0).toUpperCase() + response.nombre.slice(1) + " " + response.apellido.charAt(0).toUpperCase() + response.apellido.slice(1);;
                setIsLoadingUser(false);
            } catch (error) {
                console.log(error);
            }
        };
        loadUserData();
    }, [history])

    const classes = useStyles();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSignOut = () => {
        try {
            Auth.signOut();
            setAnchorEl(null);
            history.push("/login");
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div >
            <AppBar color="secondary" position="fixed" className={classes.appBar}>
                <Toolbar >
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        onClick={() => setOpenMenu(!menuState)}
                    >
                        <img style={{ width: "2rem" }} alt="logo" src={Logo}></img>
                    </IconButton>
                    <Typography variant="h5" className="appbar-imperium">
                        IMPERIUM
                        </Typography>
                    {isLoadingUser ?
                        <>
                            <Skeleton animation="wave" variant="circle" width={20} height={20} className={classes.skeletonAvatar} />
                            <Skeleton animation="wave" variant="text" width={70} height={15} />
                        </>
                        :
                        <div>
                            <Button
                                aria-controls="simple-menu"
                                color="inherit"
                                startIcon={<AccountCircleIcon />}
                                className="btn-capitalize"
                                aria-haspopup="true"
                                onClick={handleClick}>
                                {userName.current}
                            </Button>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem
                                    color="primary"
                                    onClick={handleSignOut}
                                >Cerrar sesión</MenuItem>
                            </Menu>
                        </div>
                    }
                </Toolbar>
            </AppBar>
        </div>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);