import React, { useState } from "react";
import {
    Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, Button, TextField, LinearProgress
} from "@material-ui/core";
import { connect } from "react-redux";
import { API } from "aws-amplify";
import Alert from '@material-ui/lab/Alert';

const mapStateToProps = (state, props) => ({
    open: props.open,
    setOpen: props.setOpen
});

const mapDispatchToProps = dispatch => ({
    showError(error) {
        dispatch({
            type: "SHOW_ERROR",
            error
        })
    },
});

const DialogAddLicencias = ({open, setOpen, showError}) => {

    const [descripcion, setDescripcion] = useState('');
    const [licencia, setLicencia] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const handleClose = () => {
        setOpen(false);
    };

    const handleSaveLicencia = async() => {
        setIsLoading(true);
        try {
            const data = {
                licencia: licencia.trim(),
                descripcion: descripcion.trim().toLowerCase(),
            };
            const response = await API.post("imperium", "/crearLicencia",{
                body: data
            });
            setIsLoading(false);
            if (response.fail){
                console.log(response);
                setErrorMessage(response.description)
            } else {
                handleClose();
            }
        } catch (error) {
            setIsLoading(false);
            if(error.show){
                handleClose();
                showError(error);
            } else {
                console.log(error);
                const erro = {
                    show: true,
                    code: "AppException",
                    description: "Error en la aplicación",
                }
                handleClose();
                showError(erro);
            }
        }
    };

    const enableSaveLicencia = () => {
        return licencia.length > 0 &&
        descripcion.length > 4
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="add-licencia-dialog"
            disableBackdropClick
            disableEscapeKeyDown
        >
            <DialogTitle id="add-licencia-dialog">Nueva Licencia</DialogTitle>
            <DialogContent>
                <DialogContentText variant='h6'>
                    Vamos a crear una nueva lincencia
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="licencia"
                    label="Licencia"
                    type="text"
                    value={licencia}
                    onChange={e=>setLicencia(e.target.value)}
                    fullWidth
                    InputProps={{
                        readOnly:isLoading
                    }}
                />
                <TextField
                    margin="dense"
                    id="descripcion"
                    label="Descripción licencia"
                    type="text"
                    value={descripcion}
                    onChange={e=>setDescripcion(e.target.value)}
                    multiline
                    fullWidth
                    InputProps={{
                        readOnly:isLoading
                    }}
                />
            </DialogContent>
            {isLoading ?
                <LinearProgress />
                :
                <></>
            }
            <DialogActions>
                {errorMessage ? 
                    <Alert severity="error">{errorMessage}</Alert>
                :
                    <></>    
                }
                <Button onClick={handleClose} 
                    variant="outlined" 
                    color="secondary"
                    disabled={isLoading}
                >
                    Cancelar
                </Button>
                <Button 
                    onClick={()=>handleSaveLicencia()} 
                    disabled={isLoading ? isLoading : !enableSaveLicencia()}
                    variant="contained" 
                    color="secondary"
                    disableElevation
                >
                    Guardar
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(DialogAddLicencias);