import API from "@aws-amplify/api";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Table, TableRow, TableBody, TableCell,
    Button, makeStyles, Menu, MenuItem } from "@material-ui/core";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import SkeletonTable from "./skeletonTable";
import { DataGrid, GridColDef } from '@material-ui/data-grid';
import AddIcon from '@material-ui/icons/Add';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DeleteIcon from '@material-ui/icons/Delete';
import DialogAddLicencias from "./dialogAddLicencias";

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
    showError(error) {
        dispatch({
            type: "SHOW_ERROR",
            error
        })
    },
});

const columns: GridColDef[] = [
    { field: 'licencia', headerName: 'Licencia', width: 150},
    { field: 'descripcion', headerName: 'Descripcion', width: 350, editable: true},
];

const useStyles = makeStyles((theme)=>({
    btnBox: {
        border: '1px solid '+theme.palette.secondary.contrastText,
        borderRadius: 8
    },
    btnsMargin: {
        marginBottom: theme.spacing(0.5),
        marginTop: theme.spacing(0.5)
    }
}));

const Licencias = ({showError}) => {

    const [isLoading, setIsLoading] = useState(true);
    const [isLicenciaSelected ,setIsLicenciaSelected] = useState(false);
    const [editRowsModel, setEditRowsModel] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);

    const isFirstRender = useRef(true);
    const licencias = useRef([]);
    const licenciaSelectedIndex = useRef(null);

    const classes = useStyles();

    const loadLicensias = useCallback(async()=>{
        setIsLoading(true);
        try {
            const licenciasData = await API.get("imperium", "/getLicencias");
            if(licenciasData.fail){
                console.log(licenciasData);
                const err = {
                    show: true,
                    code: "GetLicenciasException",
                    description: licenciasData.description,
                }
                throw(err);
            }
            for (let index = 0; index < licenciasData.length; index++) {
                licenciasData[index].id = index;
            }
            licencias.current = licenciasData;
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            if(error.show){
                showError(error);
            } else {
                const erro = {
                    show: true,
                    code: "AppException",
                    description: "Error en la aplicación",
                }
                showError(erro);
            }
        }
    },[showError]);

    useEffect(()=>{
        if(isFirstRender.current){
            isFirstRender.current = false;
            loadLicensias();
        }
    },[loadLicensias]);

    const handleSelectionModel = (selection) => {
        setIsLicenciaSelected(true);
        licenciaSelectedIndex.current = selection.selectionModel[0];
    };

    const handleEditLicencia = async(dataField, oldData) => {
        //console.log(dataField);
        try {
            if(dataField.props.value.length === 0){
                const err = {
                    show: true,
                    code: "InvalidValueException",
                    description: "El campo no puede estar vacio"
                };
                throw (err);
            }
            const data = {
                params: [
                    {
                        propiedad: dataField.field,
                        value: dataField.props.value
                    }
                ]
            };
            // console.log(data);
            const result = await API.put("imperium", "/licencias/"+licencias.current[licenciaSelectedIndex.current].licencia,{
                body: data
            });
            if(result.fail){
                console.log(result);
                const err = {
                    show: true,
                    code: "UpdateLicenciaException",
                    description: "Error almacenando cambios"
                };
                throw (err);
            }
            licencias.current[dataField.id].[dataField.field] = dataField.props.value;
        } catch (error) {
            console.log(error);
            if(error.show){
                showError(error);
            } else {
                const erro = {
                    show: true,
                    code: "AppException",
                    description: "Error en la aplicación",
                }
                showError(erro);
            }
            const newState = {};
            newState[dataField.id] = {
            ...editRowsModel[dataField.id],
            [dataField.field]: { value: oldData },
            };
            setEditRowsModel((state) => ({ ...state, ...newState }));
        }
    };

    const handleEditCellChangeCommitted = (dataField) => {
        const oldData = licencias.current[dataField.id].[dataField.field];
        if(oldData !== dataField.props.value){
            handleEditLicencia(dataField, oldData);
        }
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleAddLicencia = () => {
        setOpen(true);
    };

    const handleDeleteLicencia = async() => {
        handleClose();
        setIsLoading(true);
        try {
            const newState = licencias.current.map(
                i => ({...i})
            )
            newState.splice(licenciaSelectedIndex.current, 1);
            const result = await API.del("imperium","/licencias/"+licencias.current[licenciaSelectedIndex.current].licencia);
            if(result.fail){
                console.log(result);
                const err = {
                    show: true,
                    code: "DeleteLicenciaException",
                    description: "Error borrando la licencia"
                };
                throw (err);
            }
            licencias.current = newState;
            licenciaSelectedIndex.current = null;
            setIsLicenciaSelected(false);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            if(error.show){
                showError(error);
            } else {
                const erro = {
                    show: true,
                    code: "AppException",
                    description: "Error en la aplicación",
                }
                showError(erro);
            }
        }
    };

    return (
        <div>
            {open && <DialogAddLicencias open={open} setOpen={setOpen}/>}
            {isLoading ? 
                <SkeletonTable />
            :
                <>
                    <Row className="justify-content-end">
                        <Col className={"text-right mr-3 " + classes.btnBox}  sm={'auto'}>
                            <Button 
                                className={classes.btnsMargin} 
                                onClick={()=> handleAddLicencia()}
                            ><AddIcon color="secondary"/></Button>
                            <Button 
                                className={classes.btnsMargin} 
                                onClick={()=> loadLicensias()}
                            ><AutorenewIcon color="secondary"/></Button>
                            <Button
                                aria-controls="delete-btn"
                                color="inherit"
                                className={classes.btnsMargin} 
                                aria-haspopup="true"
                                onClick={handleClick}
                                disabled={!isLicenciaSelected}
                            ><DeleteIcon color="error"/></Button>
                            <Menu
                                id="delete-btn"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem
                                    color="primary"
                                    onClick={handleDeleteLicencia}
                                >¿Confirma eliminar esta licencia?</MenuItem>
                            </Menu>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col style={{ height: 300, width: '100%' }}>
                            {licencias.current.length === 0 ?
                                <Table style={{ width: '100%' }}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <h4>No hay licencias que mostrar</h4>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                
                            
                            :
                                <DataGrid 
                                    rows={licencias.current} 
                                    columns={columns} 
                                    onSelectionModelChange={handleSelectionModel}
                                    editRowsModel={editRowsModel}
                                    onEditCellChangeCommitted={handleEditCellChangeCommitted} 
                                />
                            }
                        </Col>
                    </Row>
                </>
            }
        </div>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(Licencias);