import React, { useEffect } from "react";
import clsx from 'clsx';
import { Auth } from "aws-amplify";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap"
import {
    Drawer, List, ListItem, ListItemIcon,
    ListItemText, Toolbar, Divider
} from "@material-ui/core";
import ContactsIcon from '@material-ui/icons/Contacts';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import SettingsIcon from '@material-ui/icons/Settings';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import { makeStyles } from '@material-ui/core/styles';
import Header from "../components/header";
import Products from "./products";
import ProductData from "./productData";
import ModalError from "../components/modalError";
import Licencias from "../components/licencias";

const mapStateToProps = state => ({
    menuState: state.menuState,
    error: state.error,
    actualPage: state.actualPage,
});

const mapDispatchToProps = dispatch => ({
    setActualPage(actual_page) {
        dispatch({
            type: "SET_ACTUAL_PAGE",
            actual_page
        })
    },
});

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        background: 'black'
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerContainer: {
        overflow: 'auto',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    content: {
        flexGrow: 1,
        paddingTop: theme.spacing(10),
        paddingLeft: theme.spacing(9),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(10),
            paddingLeft: theme.spacing(0),
        },
    },
}));

const Home = ({ actualPage, menuState, error, setActualPage }) => {

    const history = useHistory();
    const classes = useStyles();

    useEffect(() => {
        const isUserSign = async () => {
            //setIsLoading(true);
            try {
                await Auth.currentAuthenticatedUser();
                // const response = await API.get('imperium', '/usuarios/'+cu.username,{
                //     queryStringParameters: {
                //         get: ['nombre', 'apellido']
                //     }
                // });
                // console.log(response);
            } catch (error) {
                console.log(error);
                //setIsLoading(false);
                history.push("/login");
            }
        };
        isUserSign();
    }, [history]);

    const componentSelected = () => {
        switch (actualPage) {
            case 1:
                return <Products />
            case 2:
                return <ProductData />
            
            case 3:
                return <Licencias />
            default:
                return <></>
        }
    }

    return (
        <div>
            {error.show && <ModalError />}
            <Header />
            <Row >
                <Col sm={'auto'} md={'auto'}>
                    <Drawer
                        variant="permanent"
                        className={classes.drawer && clsx(classes.drawer, {
                            [classes.drawerOpen]: menuState,
                            [classes.drawerClose]: !menuState,
                        })}
                        classes={{
                            paper: clsx({
                                [classes.drawerOpen]: menuState,
                                [classes.drawerClose]: !menuState,
                            }),
                        }}
                    >
                        <Toolbar />
                        <div className={classes.drawerContainer}>
                            <List>
                                <ListItem title="Productos" button onClick={()=>setActualPage(1)}>
                                    <ListItemIcon><BusinessCenterIcon color="primary"/></ListItemIcon>
                                    <ListItemText>Productos </ListItemText>
                                </ListItem>
                            </List>
                            <List>
                                <ListItem title="Licencias" button onClick={()=>setActualPage(3)}>
                                    <ListItemIcon><LibraryAddCheckIcon color="primary"/></ListItemIcon>
                                    <ListItemText>Licencias </ListItemText>
                                </ListItem>
                            </List>
                            <List>
                                <ListItem title="Clientes" button>
                                    <ListItemIcon><ContactsIcon color="primary"/></ListItemIcon>
                                    <ListItemText>Clientes</ListItemText>
                                </ListItem>
                            </List>
                            <List>
                                <ListItem title="Finanzas" button>
                                    <ListItemIcon><AttachMoneyIcon color="primary"/></ListItemIcon>
                                    <ListItemText>Finanzas</ListItemText>
                                </ListItem>
                            </List>
                            <List>
                                <ListItem title="Configuración" button>
                                    <ListItemIcon><SettingsIcon color="primary"/></ListItemIcon>
                                    <ListItemText>Configuración</ListItemText>
                                </ListItem>
                            </List>
                            <Divider />
                        </div>
                    </Drawer>
                </Col>
                <Col className={classes.content}>
                    {componentSelected()}
                </Col>
            </Row>
        </div>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);