import { Card, CardActionArea, CardContent, 
    CardMedia, makeStyles, Typography } from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import React, { useState, useRef, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import nuevo from "../img/nuevo.png";
import DialogAddProduct from "../components/dialogAddProduct";
import { connect } from "react-redux";
import API from "@aws-amplify/api";

const mapStateToProps = state => ({
    products: state.products,
});

const mapDispatchToProps = dispatch => ({
    setProducts(products) {
        dispatch({
            type: "SET_PRODUCTS",
            products
        })
    },
    setProductSelected(product_selected) {
        dispatch({
            type: "SET_PRODUCT_SELECTED",
            product_selected
        })
    },
    setActualPage(actual_page) {
        dispatch({
            type: "SET_ACTUAL_PAGE",
            actual_page
        })
    },
    showError(error) {
        dispatch({
            type: "SHOW_ERROR",
            error
        })
    },
});

const useStyles = makeStyles((theme) => ({
    card: {
        width: 245,
        //background: theme.palette.primary.light
    },
    media: {
        height: 140,
    }
}));

const Products = ({products, showError, setProducts, setProductSelected, setActualPage}) => {
    
    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(true);
    const [open, setOpen] = useState(false);

    const isFirstRender = useRef(true);

    useEffect(()=>{
        //Cargar productos en DB
        const loadProducts = async() =>{
            try {
                const productsDB = await API.get("imperium","/getProducts");
                if(productsDB.fail){
                    console.log(productsDB);
                    const err = {
                        show: true,
                        code: "GetProductsException",
                        description: productsDB.description,
                    }
                    throw(err);
                }
                //console.log(productsDB);
                setProducts(productsDB);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                if(error.show){
                    showError(error);
                } else {
                    console.log(error);
                    const erro = {
                        show: true,
                        code: "AppException",
                        description: "Error en la aplicación",
                    }
                    showError(erro);
                }
            }
        }
        if(isFirstRender.current){
            isFirstRender.current = false;
            loadProducts();
        }
    },[showError, setProducts]);

    const handleProductSelected = (productSelected) => {
        setProductSelected(productSelected);
        setActualPage(2);
    };

    return (
        <Row>
            {open && <DialogAddProduct open={open} setOpen={setOpen}/>}
            {isLoading ? 
                <>
                    <Col sm={'auto'}>
                        <Skeleton animation="wave" width={245} className={classes.media} style={{ marginBottom: -20 }}/>
                        <Skeleton animation="wave" width={245} height={15} style={{ marginBottom: 1 }} />
                        <Skeleton animation="wave" width={'80%'} height={15} style={{ marginBottom: 1 }} />
                    </Col>
                    <Col sm={'auto'}>
                    <Skeleton animation="wave" width={245} className={classes.media} style={{ marginBottom: -20 }}/>
                        <Skeleton animation="wave" width={245} height={15} style={{ marginBottom: 1 }} />
                        <Skeleton animation="wave" width={'80%'} height={15} style={{ marginBottom: 1 }} />
                    </Col>
                    <Col sm={'auto'}>
                    <Skeleton animation="wave" width={245} className={classes.media} style={{ marginBottom: -20 }}/>
                        <Skeleton animation="wave" width={245} height={15} style={{ marginBottom: 1 }} />
                        <Skeleton animation="wave" width={'80%'} height={15} style={{ marginBottom: 1 }} />
                    </Col>
                </>
            :
                <>
                    {products.map(x => {

                        return (
                            <Col key={x.productoId} className="mb-4" sm={'auto'} >
                                <Card className={classes.card} >
                                    <CardActionArea 
                                        onClick={()=>handleProductSelected(x)}
                                    >
                                        <CardMedia 
                                            className={classes.media}
                                            image={x.imagen}
                                            title='logo producto'
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant='h5' component='h2'>
                                                {x.productoId.charAt(0).toUpperCase()+x.productoId.slice(1)}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                {x.descripcion.charAt(0).toUpperCase()+x.descripcion.slice(1)}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Col>
                        )
                    })}
                    <Col className="mb-4" sm={'auto'}>
                        <Card className={classes.card} >
                            <CardActionArea 
                                onClick={()=>setOpen(true)}
                            >
                                <CardMedia 
                                    className={classes.media}
                                    image={nuevo}
                                    title='nuevo producto'
                                />
                                <CardContent>
                                    <Typography gutterBottom variant='h5' component='h2'>
                                        Nuevo producto
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Adicionar un nuevo producto
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Col>
                </>
            }
        </Row>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);