import React, { useState, useRef, useEffect } from "react";
import { API } from "aws-amplify";
import { Row, Col, Button, DropdownButton, Dropdown, ButtonGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { useCallback } from "react";
import SkeletonTable from "./skeletonTable";
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from "react-bootstrap-table2-paginator";
import AddIcon from '@material-ui/icons/Add';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DeleteIcon from '@material-ui/icons/Delete';
import DialogAddRocketManager from "./dialogAddRocketManager";
import DialogAddCristalManager from "./dialogAddCristalManager";
import EditIcon from '@material-ui/icons/Edit';

const mapStateToProps = (state, props) => ({
    productSelected: state.productSelected,
    instanciaSelect: state.instanciaSelect,
    institucionId: props.institucionId
});

const mapDispatchToProps = dispatch => ({
    showError(error) {
        dispatch({
            type: "SHOW_ERROR",
            error
        })
    },
});

const columns = [
    {
        dataField:"nombre", 
        text:"Nombre",
        title: true,
        style: { overflow: 'hidden', textOverflow: 'ellipsis', textTransform: 'capitalize' },
        headerStyle: {
            overflow: 'hidden', 
            textOverflow: 'ellipsis',
        }
    },
    {
        dataField: "apellido", 
        text: "Apellido",
        title: true,
        style: { overflow: 'hidden', textOverflow: 'ellipsis', textTransform: 'capitalize' },
        headerStyle: {
            overflow: 'hidden', 
            textOverflow: 'ellipsis',
        }
    },
    {
        dataField: "email", 
        text: "Email",
        title: true,
        style: { overflow: 'hidden', textOverflow: 'ellipsis' },
        headerStyle: {
            overflow: 'hidden', 
            textOverflow: 'ellipsis',
        }
    },
];

const ManagersPorInstancia = ({productSelected, instanciaSelect, institucionId, showError}) => {

    const [isLoading, setIsLoading] = useState(true);
    const [enableDelManager,setEnableDelManager] = useState(false);
    const [showAddManagerRocketDialog, setShowAddManagerRocketDialog] = useState(false);
    const [showAddManagerCristalDialog, setShowAddManagerCristalDialog] = useState(false);
    
    const isFirstRender = useRef(true);
    const managersData = useRef([]);
    const rowSelected = useRef(null);
    const keyFieldTable = useRef('usuarioId')

    const getManagers = useCallback(async()=>{
        setIsLoading(true);
        managersData.current = [];
        rowSelected.current = null;
        setEnableDelManager(false);
        var get = null;
        switch (productSelected.productoId) {
            case 'rocket':
                get = ['usuarioId', 'nombre', 'apellido', 'email'];
                keyFieldTable.current = 'usuarioId';
                break;
            case 'cristal':
                get = ['uId', 'nombre', 'apellido', 'email'];
                keyFieldTable.current = 'uId';
                break;
            default:
                break;
        }
        try {
            const qsp = {
                productoId: productSelected.productoId,
                prefijo: instanciaSelect,
                institucionId: institucionId,
                get: get
            };
            const managers = await API.get("imperium", "/getManagers", {
                queryStringParameters: qsp,
            });
            if(managers.fail){
                console.log(managers);
                const err = {
                    show: true,
                    code: "GetManagersException",
                    description: managers.description,
                }
                throw(err);
            }
            managersData.current = managers;
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            if(error.show){
                showError(error);
            } else {
                console.log(error);
                const erro = {
                    show: true,
                    code: "AppException",
                    description: "Error en la aplicación",
                }
                showError(erro);
            }
        }
    },[productSelected, instanciaSelect, institucionId, showError]);

    useEffect(() => {
        if(isFirstRender.current){
            isFirstRender.current = false;
            getManagers()
        }
    },[getManagers]);

    const handleOnSelect = (row, isSelect) => {
        if(isSelect){
            rowSelected.current = row;
            setEnableDelManager(true);
        } else {
            rowSelected.current = null;
            setEnableDelManager(false);
        }
    };

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        clickToEdit: true,
        onSelect: handleOnSelect,
        //onSelectAll: handleOnSelectAll
    };

    const handleAddManager = () => {
        switch (productSelected.productoId) {
            case 'rocket':
                setShowAddManagerRocketDialog(true);
                break;
            case 'cristal':
                setShowAddManagerCristalDialog(true);
                break;
            default:
                break;
        }
    };

    const handleDelManager = async() => {
        setIsLoading(true);
        switch (productSelected.productoId) {
            case 'rocket':
                const data = {
                    productoId: productSelected.productoId.trim().toLowerCase(),
                    prefijo: instanciaSelect.trim().toLowerCase(),
                    institucionId: institucionId,
                    usuarioId: rowSelected.current.usuarioId,
                };
                try {
                    const result = await API.del("imperium", "/deleteManager",{
                        body: data,
                    });
                    if(result.fail){
                        console.log(result);
                        rowSelected.current = null;
                        setEnableDelManager(false);
                        const err = {
                            show: true,
                            code: "DeleteManagerException",
                            description: result.description,
                        }
                        throw(err);
                    }
                    getManagers();
                } catch (error) {
                    setIsLoading(false);
                    if(error.show){
                        showError(error);
                    } else {
                        console.log(error);
                        const erro = {
                            show: true,
                            code: "AppException",
                            description: "Error en la aplicación",
                        }
                        showError(erro);
                    }
                }
                break;
            case 'cristal':
                const cristalData = {
                    productoId: productSelected.productoId.trim().toLowerCase(),
                    prefijo: instanciaSelect.trim().toLowerCase(),
                    institucionId: institucionId,
                    usuarioId: rowSelected.current.uId,
                };
                try {
                    const result = await API.del("imperium", "/deleteManager",{
                        body: cristalData,
                    });
                    if(result.fail){
                        console.log(result);
                        rowSelected.current = null;
                        setEnableDelManager(false);
                        const err = {
                            show: true,
                            code: "DeleteManagerException",
                            description: result.description,
                        }
                        throw(err);
                    }
                    getManagers();
                } catch (error) {
                    setIsLoading(false);
                    if(error.show){
                        showError(error);
                    } else {
                        console.log(error);
                        const erro = {
                            show: true,
                            code: "AppException",
                            description: "Error en la aplicación",
                        }
                        showError(erro);
                    }
                }
                break;
            default:
                break;
        }
    }

    return (
        <div>
            {showAddManagerRocketDialog && 
                <DialogAddRocketManager 
                    open={showAddManagerRocketDialog} 
                    setOpen={setShowAddManagerRocketDialog}
                    institucionId={institucionId}
                    managerEdit={rowSelected.current ? rowSelected.current.usuarioId : null}
            />}
            {showAddManagerCristalDialog && 
                <DialogAddCristalManager 
                    open={showAddManagerCristalDialog} 
                    setOpen={setShowAddManagerCristalDialog}
                    institucionId={institucionId}
                    managerEdit={rowSelected.current ? rowSelected.current.uId : null}
            />}
            {isLoading ? 
                <SkeletonTable />
            : 
                <div>
                    <Row>
                        <Col>
                            <h4>Managers</h4>
                        </Col>
                        <Col className="text-right">
                            <Button 
                                className="custom-primary-btn"
                                onClick={()=>handleAddManager()}
                            >{rowSelected.current ? <EditIcon /> : <AddIcon />}</Button>
                            <Button 
                                className="custom-primary-btn ml-1 mr-3"
                                onClick={()=>getManagers()}
                            ><AutorenewIcon /></Button>
                            <DropdownButton 
                                as={ButtonGroup}
                                variant="outline-danger"
                                title={<DeleteIcon />}
                                disabled={!enableDelManager} 
                            >
                                <Dropdown.Item eventKey="1">¿Segur@?</Dropdown.Item>
                                    <Dropdown.Divider />
                                <Dropdown.Item 
                                    onClick={()=>handleDelManager()}
                                >Si, elimina este manager</Dropdown.Item>
                            </DropdownButton>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <BootstrapTable 
                                keyField={keyFieldTable.current}
                                data={managersData.current}
                                columns={columns}
                                noDataIndication="No se encontrarón managers"
                                bordered={ false }
                                selectRow={selectRow}
                                pagination={paginationFactory()}
                            />
                        </Col>
                    </Row>
                </div>
            }
        </div>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(ManagersPorInstancia);