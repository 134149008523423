import './App.css';
import { Container } from "react-bootstrap";
import { Provider } from "react-redux";
import Routes from "./Routes";
import store from "./store";
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  palette:{
      primary:{
          main: "#009bde"
      },
      secondary:{
        main: "#006c9b"
      },
      // error:{
      //   main: '#f44336'
      // },
  }
});

function App() {
  return (
    <Container fluid>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Routes />
        </ThemeProvider>
      </Provider>
    </Container>
  );
}

export default App;
