import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/home.js";
import Login from "./containers/login.js";

const Routes = () => {
    return (
        <Switch>
            <Route exact path="/">
                <Home />
            </Route>
            <Route exact path="/login">
                <Login />
            </Route>
        </Switch>
    );
};

export default Routes;