import React from "react";

const AnaliticsProducto = () => {

    return(
        <div>
            <h1>analitics producto</h1>
        </div>
    )
};

export default AnaliticsProducto;