const dev = {
    apiGateway: {
        REGION: "us-east-2",
    URL: "https://iezgrw42z0.execute-api.us-east-2.amazonaws.com/prod"
    },
    cognito: {
        REGION: "us-east-2",
      USER_POOL_ID: "us-east-2_BLF9diAcW",
      APP_CLIENT_ID: "5o422l4ts662ou32dadkm3pgqk",
      IDENTITY_POOL_ID: "us-east-2:d059cd80-eb2b-4e76-915c-1d5fd12ff6db"
    },
    stripe_pk: ""
};

const prod = {
  apiGateway: {
    REGION: "us-east-2",
    URL: "https://iezgrw42z0.execute-api.us-east-2.amazonaws.com/prod"
  },
  cognito: {
      REGION: "us-east-2",
      USER_POOL_ID: "us-east-2_BLF9diAcW",
      APP_CLIENT_ID: "5o422l4ts662ou32dadkm3pgqk",
      IDENTITY_POOL_ID: "us-east-2:d059cd80-eb2b-4e76-915c-1d5fd12ff6db"
  }
};

// Si no se especifica el ambiente (dev ó prod), se asume que el ambiente es dev
const state = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

const config = {
    // Aca las configuraciones para ambos ambientes
    customUser: "userTest",
    customUserPass: "userTestPass",
    ...state
  };

export default config;