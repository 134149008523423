import React from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import ErrorIcon from '@material-ui/icons/Error';

const mapStateToProps = (state, props) => ({
    error: state.error
});

const mapDispatchToProps = dispatch => ({
    showError(error) {
        dispatch({
            type: "SHOW_ERROR",
            error
        })
    },
});

const ModalError = ({error, showError}) => {

    const handleCloseErrorModal = () => {
        showError({});
    };

    return (
        <Modal
            show={true}
            onHide={() => handleCloseErrorModal()}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title><ErrorIcon color="error" /></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error.description}
            </Modal.Body>
        </Modal>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalError)