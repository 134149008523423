import React, { useState } from "react";
import {
    Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, Button, TextField, LinearProgress
} from "@material-ui/core";
import { connect } from "react-redux";
import { API } from "aws-amplify";
import Alert from '@material-ui/lab/Alert';

const mapStateToProps = (state, props) => ({
    products: state.products,
    open: props.open,
    setOpen: props.setOpen
});

const mapDispatchToProps = dispatch => ({
    setProducts(products) {
        dispatch({
            type: "SET_PRODUCTS",
            products
        })
    },
    showError(error) {
        dispatch({
            type: "SHOW_ERROR",
            error
        })
    },
});

const DialogAddProduct = ({open, products, setOpen, showError, setProducts}) => {

    const [productoId, setProductoId] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [imagen, setImagen] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const handleClose = () => {
        setOpen(false);
    };

    const handleSaveProduct = async() => {
        setIsLoading(true);
        try {
            const data = {
                productoId: productoId.trim().toLowerCase(),
                descripcion: descripcion.trim().toLowerCase(),
                imagen: imagen.trim().toLowerCase()
            };
            const response = await API.post("imperium", "/crearProducto",{
                body: data
            });
            if (response.fail){
                console.log(response);
                setErrorMessage(response.description)
                // const error = {
                //     show: true,
                //     code: "SaveProductException",
                //     description: "Error creando nuevo producto",
                // }
                // throw (error);
            } else {
                const localProducts = products;
                localProducts.push(data);
                handleClose();
                setProducts(localProducts);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            if(error.show){
                handleClose();
                showError(error);
            } else {
                console.log(error);
                const erro = {
                    show: true,
                    code: "AppException",
                    description: "Error en la aplicación",
                }
                handleClose();
                showError(erro);
            }
        }
    };

    const enableSaveproduct = () => {
        return productoId.length > 2 &&
        descripcion.length > 4 &&
        imagen.length > 4
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="add-product-dialog"
            disableBackdropClick
            disableEscapeKeyDown
        >
            <DialogTitle id="add-product-dialog">Nuevo producto</DialogTitle>
            <DialogContent>
                <DialogContentText variant='h6'>
                    Vamos a crear un nuevo producto!
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="productoId"
                    label="Producto"
                    type="text"
                    value={productoId}
                    onChange={e=>setProductoId(e.target.value)}
                    fullWidth
                    InputProps={{
                        readOnly:isLoading
                    }}
                />
                <TextField
                    margin="dense"
                    id="descripcion"
                    label="Descripción"
                    type="text"
                    value={descripcion}
                    onChange={e=>setDescripcion(e.target.value)}
                    multiline
                    fullWidth
                    InputProps={{
                        readOnly:isLoading
                    }}
                />
                <TextField
                    margin="dense"
                    id="logo"
                    label="Imagen/Logo URL"
                    type="text"
                    value={imagen}
                    onChange={e=>setImagen(e.target.value)}
                    fullWidth
                    InputProps={{
                        readOnly:isLoading
                    }}
                />
            </DialogContent>
            {isLoading ?
                <LinearProgress />
                // <Row className="justify-content-center">
                //     <Col sm={6} md={5}>
                //         <LinearProgress />
                //     </Col>
                // </Row>
                :
                <></>
            }
            <DialogActions>
                {errorMessage ? 
                    <Alert severity="error">{errorMessage}</Alert>
                :
                    <></>    
                }
                <Button onClick={handleClose} 
                    variant="outlined" 
                    color="secondary"
                    disabled={isLoading}
                >
                    Cancelar
                </Button>
                <Button 
                    onClick={()=>handleSaveProduct()} 
                    disabled={isLoading ? isLoading : !enableSaveproduct()}
                    variant="contained" 
                    color="secondary"
                    disableElevation
                >
                    Guardar
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(DialogAddProduct);