import React, { useState, useRef, useEffect, useCallback } from "react";
import {
    Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, Button, TextField, 
    LinearProgress, FormControl, 
    InputLabel, Select, MenuItem
} from "@material-ui/core";
import { connect } from "react-redux";
import { API } from "aws-amplify";
import Alert from '@material-ui/lab/Alert';
import { Row, Col, Dropdown, DropdownButton, ButtonGroup, Button as Btn } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from "react-bootstrap-table2-paginator";
import cellEditFactory from 'react-bootstrap-table2-editor';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import SkeletonTable from "./skeletonTable";
import { nanoid } from "nanoid";

const mapStateToProps = (state, props) => ({
    productSelected: state.productSelected,
    instanciaSelect: state.instanciaSelect,
    institucionId: props.institucionId,
    open: props.open,
    setOpen: props.setOpen
});

const mapDispatchToProps = dispatch => ({
    showError(error) {
        dispatch({
            type: "SHOW_ERROR",
            error
        })
    },
});

const columns = [
    {
        dataField:"etiqueta", 
        text:"Etiqueta",
        title: true,
        style: { overflow: 'hidden', textOverflow: 'ellipsis' },
        headerStyle: {
            // width: '15%',
            overflow: 'hidden', 
            textOverflow: 'ellipsis',
        }
    },
    {
        dataField: "value", 
        text: "Valor",
        title: true,
        style: { overflow: 'hidden', textOverflow: 'ellipsis' },
        headerStyle: {
            overflow: 'hidden', 
            textOverflow: 'ellipsis',
        },
        editable: true,
    },
];

const DialogAddInstitucion = ({open, setOpen, showError, productSelected, instanciaSelect, institucionId}) => {

    const [tributariaId, setTributariaId] = useState(''); //No editable
    const [alias, setAlias] = useState('');
    const [direccion, setDireccion] = useState('');
    const [licencia, setLicencia] = useState(''); //Select de la tabla licencias de imperium
    const [nombre, setNombre] = useState('');
    const [precio, setPrecio] = useState(0);
    const [estudiantes, setEstudiantes] = useState(0); //Solo se muestra si el producto es Rocket
    const [licencias, setLicencias] = useState(0);
    const [loadInformacion, setLoadInformacion] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [enableDelInformacion, setEnableDelInformacion] = useState(false);
    const [showLicences, setShowLicences] = useState(false);

    const productoId = useRef(productSelected.productoId);
    const isFirstRender = useRef(true);
    const institucionData = useRef(null);
    const licenciaData = useRef([]);
    const informacionTemp = useRef([]);
    const rowSelected = useRef(null);

    const handleClose = useCallback(() => {
        setOpen(false);
    },[setOpen]);

    const getInstitucionData = useCallback(async()=>{
        setIsLoading(true);
        try {
            const qsp = {
                productoId: productoId.current.trim().toLowerCase(),
                prefijo: instanciaSelect.trim().toLowerCase(),
                institucionId: institucionId,
                get: ['*'],
            };
            const instData = await API.get("imperium", "/getInstitucion", {
                queryStringParameters: qsp,
            });
            setIsLoading(false);
            if (instData.fail){
                console.log(instData);
                setErrorMessage(instData.description)
            }
            setTributariaId(instData.tributariaId ? instData.tributariaId : '');
            setAlias(instData.alias ? instData.alias : '');
            setDireccion(instData.direccion ? instData.direccion : '');
            setLicencia(instData.licencia ? instData.licencia : '');
            setNombre(instData.nombre ? instData.nombre : '');
            setPrecio(instData.precio ? instData.precio : 0);
            setEstudiantes(instData.estudiantes ? instData.estudiantes : 0);
            setLicencias(instData.licencias ? instData.licencias : 0);
            if(instData.informacion){
                for (let index = 0; index < instData.informacion.length; index++) {
                    instData.informacion[index].id = nanoid(4);
                }
            }
            setLoadInformacion(true);
            informacionTemp.current = instData.informacion ? instData.informacion : [];
            institucionData.current = instData;
        } catch (error) {
            setIsLoading(false);
            if(error.show){
                handleClose();
                showError(error);
            } else {
                console.log(error);
                const erro = {
                    show: true,
                    code: "AppException",
                    description: "Error en la aplicación",
                }
                handleClose();
                showError(erro);
            }
        }
    },[instanciaSelect, institucionId, handleClose, showError]);

    const getLicencias = useCallback(async() => {
        try {
            const licenciasResult = await API.get("imperium", "/getLicencias");
            if(licenciasResult.fail){
                console.log(licenciasResult);
                setErrorMessage("Error cargando licencias, no sera posible guardar/actualizar la institución");
            }
            setShowLicences(true);
            licenciaData.current = licenciasResult;
            if(institucionId){
                getInstitucionData();
            } else {
                setLicencia('');
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
            setErrorMessage("Error cargando licencias, no sera posible guardar/actualizar la institución");
        }
    },[getInstitucionData, institucionId]);

    useEffect(() => {
        if(isFirstRender.current){
            isFirstRender.current = false;
            getLicencias();
        }
    }, [institucionId, getInstitucionData, getLicencias]);

    const handleUpdateInstitucion = async() => {
        setErrorMessage(null);
        setIsLoading(true);
        const cambios = [];
        if(tributariaId !== institucionData.current.tributariaId){
            const tId = {
                propiedad: 'tributariaId',
                value: tributariaId
            };
            cambios.push(tId);
        }
        if(nombre !== institucionData.current.nombre){
            const name = {
                propiedad: 'nombre',
                value: nombre
            };
            cambios.push(name);
        }
        if(direccion !== institucionData.current.direccion){
            const adress = {
                propiedad: 'direccion',
                value: direccion
            };
            cambios.push(adress);
        }
        if(alias !== institucionData.current.alias){
            const aka = {
                propiedad: 'alias',
                value: alias
            };
            cambios.push(aka);
        }
        if(licencia !== institucionData.current.licencia){
            const license = {
                propiedad: 'licencia',
                value: licencia
            };
            cambios.push(license);
        }
        if(productoId === 'rocket'){
            if(estudiantes !== institucionData.current.estudiantes){
                const students = {
                    propiedad: 'estudiantes',
                    value: estudiantes
                };
                cambios.push(students);
            }
        }
        if(licencias !== institucionData.current.licencias){
            const licenses = {
                propiedad: 'licencias',
                value: licencias
            };
            cambios.push(licenses);
        }
        if(precio !== institucionData.current.precio){
            const price = {
                propiedad: 'precio',
                value: precio
            };
            cambios.push(price);
        }
        if(informacionTemp.current.length > 0){
            const info = [];
            for (let index = 0; index < informacionTemp.current.length; index++) {
                const element = informacionTemp.current[index];
                const data = {
                    etiqueta: element.etiqueta,
                    value: element.value,
                };
                info.push(data);
            }
            const infor = {
                propiedad: "informacion",
                value: info
            }
            cambios.push(infor);
        } else {
            const info = {
                propiedad: "informacion",
                value: []
            }
            cambios.push(info);
        }
        try {
            const data = {
                productoId: productoId.current.trim().toLowerCase(),
                prefijo: instanciaSelect.trim().toLowerCase(),
                institucionId: institucionId,
                propiedades: cambios,
            };
            const result = await API.post("imperium", "/updateInstitucion",{
                body: data,
            });
            if(result.fail){
                console.log(result);
                setErrorMessage(result.description)
                setIsLoading(false);
            } else {
                handleClose();
            }
        } catch (error) {
            setIsLoading(false);
            if(error.show){
                handleClose();
                showError(error);
            } else {
                console.log(error);
                const erro = {
                    show: true,
                    code: "AppException",
                    description: "Error en la aplicación",
                }
                handleClose();
                showError(erro);
            }
        }
    };

    const handleSaveInstitucion = async() => {
        setIsLoading(true);
        setErrorMessage(null);
        try {
            const info = [];
            if(informacionTemp.current.length > 0){
                for (let index = 0; index < informacionTemp.current.length; index++) {
                    const element = informacionTemp.current[index];
                    const data = {
                        etiqueta: element.etiqueta,
                        value: element.value,
                    };
                    info.push(data);
                }
            }
            var data = {
                productoId: productoId.current.trim().toLowerCase(),
                prefijo: instanciaSelect.trim().toLowerCase(),
                tributariaId: tributariaId,
                alias: alias,
                direccion: direccion,
                licencia: licencia,
                nombre: nombre,
                precio: precio,
                licencias: licencias,
                informacion: info,
            };
            if(productoId === 'rocket'){
                data['estudiantes'] = estudiantes;
            }
            const response = await API.post("imperium", "/crearInstitucion",{
                body: data,
            });
            setIsLoading(false);
            if(response.fail){
                console.log(response);
                setErrorMessage(response.description)
            }else {
                handleClose();
            }            
        } catch (error) {
            setIsLoading(false);
            if(error.show){
                handleClose();
                showError(error);
            } else {
                console.log(error);
                const erro = {
                    show: true,
                    code: "AppException",
                    description: "Error en la aplicación",
                }
                handleClose();
                showError(erro);
            }
        }
    };

    const enableSaveInstitucion = () => {
        return (tributariaId ? tributariaId.length > 3 : false) &&
            (alias ? alias.length > 3 : false) &&
            (direccion ? direccion.length > 3 : false) &&
            (licencia ? true : false) &&
            (nombre ? nombre.length > 3 : false) &&
            (precio ? precio > 0 : false) &&
            (productoId.current.trim().toLowerCase() === 'rocket' ? (estudiantes ? estudiantes > 0 : false) : true) &&
            (licencias ? licencias > 0 : false)
    };

    const handleOnSelect = (row, isSelect) => {
        if(isSelect){
            rowSelected.current = row;
            setEnableDelInformacion(true);
        } else {
            rowSelected.current = null;
            setEnableDelInformacion(false);
        }
    };

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        clickToEdit: true,
        onSelect: handleOnSelect,
        //onSelectAll: handleOnSelectAll
    };

    const handleSaveValue = async(oldValue, newValue, row, column) => {
        if(oldValue !== newValue){

        } else {
            console.log("Nada que salvar");
        }
    };

    useEffect(() => {
        if(loadInformacion){
            setLoadInformacion(false);
        }
    },[loadInformacion]);

    const handleAddInformacion = () => {
        if(informacionTemp.current.length > 0){
            const lastRow = informacionTemp.current[(informacionTemp.current.length - 1)];
            if(lastRow.etiqueta !== '' && lastRow.value !== ''){
                const addRow = {
                    id: nanoid(4),
                    etiqueta: '',
                    value: ''
                };
                informacionTemp.current.push(addRow);
                setLoadInformacion(true);
            }
        } else {
            const addRow = {
                id: nanoid(4),
                etiqueta: '',
                value: ''
            };
            informacionTemp.current.push(addRow);
            setLoadInformacion(true);
        }
    };

    const handleDelInformacion = () => {
        var inx = null;
        for (let index = 0; index < informacionTemp.current.length; index++) {
            const element = informacionTemp.current[index];
            if(element.id === rowSelected.current.id){
                inx = index;
                break;
            }
        }
        informacionTemp.current.splice(inx, 1);
        rowSelected.current = null;
        setEnableDelInformacion(false);
        setLoadInformacion(true);
    };

    return (
        <Dialog 
            open={open}
            onClose={handleClose}
            aria-labelledby="add-institucion-dialog"
            disableBackdropClick
            disableEscapeKeyDown
        >
            <DialogTitle id="add-institucion-dialog">{institucionId ? 'Editar institución' : 'Nueva institución'}</DialogTitle>
            <DialogContent >
                <DialogContentText variant='h6'>
                    {institucionId ? 
                        'Editando institucion ' + nombre
                    :
                        'Vamos a crear una nueva institución para el producto '  + productSelected.productoId +"!"
                    }
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="tributariaId"
                    label="ID tributaria"
                    type="text"
                    value={tributariaId}
                    onChange={e=>setTributariaId(e.target.value)}
                    fullWidth
                    InputProps={{
                        readOnly: institucionId ? true : isLoading
                    }}
                />
                <TextField
                    margin="dense"
                    id="nombre"
                    label="Nombre"
                    type="text"
                    value={nombre}
                    onChange={e=>setNombre(e.target.value)}
                    fullWidth
                    InputProps={{
                        readOnly:isLoading
                    }}
                />
                <TextField
                    margin="dense"
                    id="direccion"
                    label="Dirección"
                    type="text"
                    value={direccion}
                    onChange={e=>setDireccion(e.target.value)}
                    fullWidth
                    multiline
                    InputProps={{
                        readOnly:isLoading
                    }}
                />
                <TextField
                    margin="dense"
                    id="alias"
                    label="Nombre comercial"
                    type="text"
                    value={alias}
                    onChange={e=>setAlias(e.target.value)}
                    fullWidth
                    InputProps={{
                        readOnly:isLoading
                    }}
                />
                {showLicences ? 
                    <FormControl 
                        fullWidth
                    >
                        <InputLabel id="licencia-label">Licencia:</InputLabel>
                        <Select
                            labelId="licencia-label"
                            id="licencia-select"
                            value={licencia}
                            onChange={e => setLicencia(e.target.value)}
                        >
                            {licenciaData.current.map(x=>{
                                return (
                                    <MenuItem 
                                        key={x.licencia} 
                                        value={x.licencia}
                                    >{x.licencia}: {x.descripcion.charAt(0).toUpperCase() + x.descripcion.slice(1)}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                : 
                    <></>
                }
                <Row>
                    {productoId.current.trim().toLowerCase() === 'rocket' ? 
                        <Col>
                            <TextField
                                margin="dense"
                                id="estudiantes"
                                label="Estudiantes"
                                type="number"
                                value={estudiantes}
                                onChange={e=>setEstudiantes(e.target.value)}
                                fullWidth
                                InputProps={{
                                    readOnly:isLoading
                                }}
                            />
                        </Col>
                    :
                        <></>
                    }
                    <Col>
                        <TextField
                            margin="dense"
                            id="licencias"
                            label="Licencias"
                            type="number"
                            value={licencias}
                            onChange={e=>setLicencias(e.target.value)}
                            fullWidth
                            InputProps={{
                                readOnly:isLoading
                            }}
                        />
                    </Col>
                    <Col>
                        <TextField
                            margin="dense"
                            id="precio"
                            label="Precio"
                            type="number"
                            value={precio}
                            onChange={e=>setPrecio(e.target.value)}
                            fullWidth
                            InputProps={{
                                readOnly:isLoading
                            }}
                        />
                    </Col>
                </Row>
                <DialogContentText variant='h6' className="mt-3">
                    Información adicional opcional
                </DialogContentText>
                <Row>
                    <Col className="text-right">
                        <Btn 
                            className="custom-primary-btn"
                            onClick={()=>handleAddInformacion()}
                        ><AddIcon /></Btn>
                        <DropdownButton 
                            as={ButtonGroup}
                            variant="outline-danger"
                            title={<DeleteIcon />}
                            disabled={!enableDelInformacion} 
                        >
                            <Dropdown.Item eventKey="1">¿Segur@?</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item 
                                onClick={()=>handleDelInformacion()}
                            >Si, elimina este campo</Dropdown.Item>
                        </DropdownButton>
                    </Col>
                </Row>
                {loadInformacion ? 
                    <SkeletonTable columns={columns} />
                :
                    <BootstrapTable 
                        keyField="id"
                        data={informacionTemp.current}
                        columns={columns}
                        noDataIndication="No hay información adicional"
                        selectRow={selectRow}
                        pagination={paginationFactory()}
                        cellEdit={cellEditFactory({
                            mode: 'dbclick',
                            blurToSave: true,
                            afterSaveCell: handleSaveValue
                        })}
                    />
                }
            </DialogContent>
            {isLoading ?
                <LinearProgress />
                :
                <></>
            }
            <DialogActions>
                {errorMessage ? 
                    <Alert severity="error">{errorMessage}</Alert>
                :
                    <></>    
                }
                <Button onClick={handleClose} 
                    variant="outlined" 
                    color="secondary"
                    disabled={isLoading}
                >
                    Cancelar
                </Button>
                <Button 
                    onClick={institucionId ? ()=>handleUpdateInstitucion() : ()=>handleSaveInstitucion()} 
                    disabled={isLoading ? isLoading : !enableSaveInstitucion()}
                    variant="contained" 
                    color="secondary"
                    disableElevation
                >
                    Guardar
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(DialogAddInstitucion);